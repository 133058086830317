













































import { Component, Mixins } from 'vue-property-decorator';

import PageHeader from '@/components/PageHeader/PageHeader.vue';
import ContainerTutorial from '@/components/ContainerTutorial/ContainerTutorial.vue';
import Footer from '@/components/Footer/Footer.vue';

import { LIST_ONBOARDING_ID } from '@/constant/OnboardingIDS';
import { DATA_TUTORIAL_EXAM } from './ConstantTutorialsExams';

import { OnboardingClass } from '@/mixins/Onboarding';
import { NavigationTutorials } from '@/mixins/NavigationTutorials';

@Component({
  components: {
    PageHeader,
    ContainerTutorial,
    Footer
  }
})
export default class TutorialExam extends Mixins(OnboardingClass, NavigationTutorials) {
  private isLoading = false;

  created() {
    this.saveDataTutorials(DATA_TUTORIAL_EXAM);
  }

  mounted() {
    this.setBreadCrumbs();
  }

  async updateOnboardingView() {
    try {
      this.isLoading = true;

      await this.updateOnboarding(LIST_ONBOARDING_ID.EXAM);
      await this.timerUpdateProfile(this.goToExamPage);
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }

  goToExamPage() {
    this.$router.push({
      name: 'Exams'
    });
  }

  setBreadCrumbs() {
    this.$breadcrumb.set([
      { title: 'Vestibulares', to: '/vestibular' },
      { title: 'Tutorial ', to: null }
    ]);
  }
}
