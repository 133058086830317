export const DATA_TUTORIAL_EXAM = [
  {
    id: 0,
    title: 'Olá, tudo bem com você?',
    text:
      '<p>Antes de começar a praticar, gostaríamos de explicar como funciona os nossos vestibulares.</p>'
  },
  {
    id: 1,
    title: 'Categorização dos vestibulares',
    text:
      '<p>Nossas provas vestibulares estão categorizadas em: <strong>Nacionais</strong> e <strong>Regionais.</strong></p><br><p>E além das provas, temos: o <strong>Guia do Vestibulando, que nada mais é que um guia que traz uma análise estatística dos assuntos que mais cai.</strong> Hoje temos disponível o guia da FUVEST e o do ENEM.</p>',
    imgs: [
      {
        src: 'list-books-exams.png',
        alt: 'A imagem mostra a página de listagens dos cadernos de vestibulares'
      }
    ]
  },
  {
    id: 2,
    title: 'Conteúdo relacionado',
    text:
      '<p>Ao visualizar uma prova online você também terá disponível para acesso os conteúdos relacionados desse material.</p>',
    imgs: [
      {
        src: 'show-exam.png',
        alt: 'A imagem mostra a página de visualização do vestibular'
      }
    ]
  },
  {
    id: 3,
    title: 'Download e Impressão',
    text:
      '<p>Além da visualização online, também oferecemos a opção de fazer o <strong>download</strong> ou a <strong>impressão</strong> do material.</p>',
    imgs: [
      {
        src: 'options-exam.png',
        alt: 'A imagem mostra a seção de download ou impressão do vestibular'
      }
    ]
  },
  {
    id: 4,
    title: 'Conheceu nossos Vestibulares?',
    text:
      '<p>Agora que já sabe tudo o que precisa saber sobre os nossos vestibulares. Vamos dar início aos seus estudos?</p>'
  }
];
